































import { Prop, Vue, Component } from 'vue-property-decorator';
import { Select, Option, Checkbox } from 'element-ui';

export interface Item {
    value: number | string;
    name: string;
    disabled?: boolean;
}

@Component({
    components: {
        'el-select': Select,
        'el-option': Option,
        'el-checkbox': Checkbox,
    },
})
export default class CustomSelectBordered extends Vue {
    @Prop({
        required: false,
        type: [Number, String, Array],
    })
    private value?: number | string | number[] | string[];

    @Prop({
        required: true,
        type: Array as () => Item[],
        validator(value: any): boolean {
            return value.every((item: any) => ('value' in item && 'name' in item
                && (typeof item.value === 'number' || typeof item.value === 'string')
                && typeof item.name === 'string'));
        },
    })
    private items!: Item[];

    @Prop({
        type: Boolean,
        default: false,
    })
    private disabled?: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private multiple?: boolean;

    @Prop({
        type: [String, Number],
        required: false,
    })
    private title?: string | number;

    @Prop({
        required: false,
        type: String,
    })
    private placeholder!: string;

    onChange(value: number | string) {
        this.$emit('input', value);
    }
}
