















import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import ScheduledReportsPopup from '@/modules/scheduled-reports';
import { DATA_TYPE, SCHEDULER_CONFIG, DAY_CONFIG } from '@/modules/scheduled-reports/constants';
import {
    IProperties, IFilterItem, ISchedulerConfig, IRecipient,
} from '@/modules/scheduled-reports/interfaces';
import CompsetsService, { CompsetsServiceS } from '@/modules/compsets/compsets.service';
import loop24 from '@/modules/common/filters/loop-24.filter';
import RankingService, { RankingServiceS } from '../ranking.service';

@Component({
    components: {
        ScheduledReportsPopup,
    },
})
export default class ScheduledReportsModalPage extends Vue {
    @Inject(RankingServiceS) private rankingService!: RankingService;
    @Inject(CompsetsServiceS) private compsetsService!: CompsetsService;

    readonly dataType = DATA_TYPE.RANKING;

    get properties(): IProperties {
        return {
            dataType: this.dataType,
            fileType: {
                options: ['EXCEL'],
                value: 'EXCEL',
            },
        };
    }

    get customColumns() {
        return [];
    }

    get filters(): IFilterItem[] {
        const filters = [] as IFilterItem[];

        if (this.rankingService.isLoading) {
            return filters;
        }

        if (this.compsetsService.currentCompset) {
            const options = this.compsetsService.compsets!
                .map(compset => ({
                    name: compset.name,
                    value: compset.id,
                }));
            const value = this.compsetsService.currentCompset.id;

            filters.push({
                name: 'compset',
                label: 'Comp Set',
                value,
                options,
                disableOnEdit: true,
            });
        }

        return filters;
    }

    get defaultFrequency() {
        return {
            type: SCHEDULER_CONFIG.DAILY,
            hour: loop24(new Date().getTimezoneOffset() / 60),
            minute: 0,
            month: 1,
            dayOfWeek: '0',
            dayOfMonth: 1,
            monthPeriod: DAY_CONFIG.FIRST,
            repeatEvery: 1,
        };
    }

    get frequency(): ISchedulerConfig {
        return this.defaultFrequency;
    }

    get recipients(): IRecipient[] {
        return [];
    }
}
