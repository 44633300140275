







































import ComplexSelect from '@/modules/common/components/ui-kit/complex-select.vue';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CustomSelect from '@/modules/common/components/ui-kit/custom-select.vue';
import Item from '@/modules/common/interfaces/item.interface';
import { IFilterItem } from '@/modules/scheduled-reports/interfaces';
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
    components: {
        CustomMultiSelect,
        CustomSelect,
        ComplexSelect,
    },
})
export default class FiltersSection extends Vue {
    @Prop({
        default: [],
        type: Array,
    })
    values!: IFilterItem[];

    @Prop({
        default: false,
        type: Boolean,
    })
    isEdit!: boolean;

    public localValues = JSON.parse(JSON.stringify(this.values.map(v => {
        if (!Array.isArray(v.value) || Array.isArray(v.options[0])) {
            return v;
        }

        return {
            ...v,
            value: (v.value as (string | number)[]).map(item => ({
                name: item,
                value: item,
            })),
        };
    })));

    handleChange(propName: string, value: (string | number) | Item[] | (string | number)[]) {
        if (Array.isArray(value)) {
            if (value.length && typeof value[0] !== 'object') {
                // For complex select
                this.$emit('change', {
                    propName,
                    value: {
                        key: value[0],
                        value: value[1],
                    },
                });
                return;
            }

            // For multi select
            this.$emit('change', {
                propName,
                value: (value as Item[]).map(v => v.value),
            });
            return;
        }

        // For custom select
        this.$emit('change', {
            propName,
            value,
        });
    }
}
