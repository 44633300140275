

















import CustomCheckbox from '@/modules/common/components/ui-kit/custom-checkbox.vue';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { ICustomColumn } from '../../../interfaces';

@Component({
    components: {
        CustomCheckbox,
    },
})
export default class CustomColumns extends Vue {
    @Prop({
        default: [],
        type: Array,
    })
    values!: ICustomColumn[];

    public localValues = JSON.parse(JSON.stringify(this.values));

    handleChange(propName: string, value: boolean) {
        this.$emit('change', { propName, value });
    }
}
