




































































import { Vue, Component, Prop } from 'vue-property-decorator';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import RecipientsSection from './recipients-section';
import FiltersSection from './filters-section';
import PropertiesSection from './properties-section';
import CustomColumnsSection from './custom-columns-section';
import FrequencySection from './frequency-section';
import {
    ICustomColumn,
    IFilterItem,
    IRecipient,
    IProperties,
    ISchedulerConfig,
    IForm,
    Value,
} from '../../interfaces';
import { SECTIONS } from '../../constants';

@Component({
    components: {
        ModalWrapper,
        CustomColumnsSection,
        RecipientsSection,
        FiltersSection,
        PropertiesSection,
        FrequencySection,
    },
})
export default class NewReport extends Vue {
    @Prop({
        type: Object,
        required: true,
    })
    public properties!: IProperties;

    @Prop({
        type: Array,
        required: true,
    })
    public customColumns!: ICustomColumn[];

    @Prop({
        type: Array,
        required: true,
    })
    public filters!: IFilterItem[];

    @Prop({
        type: Object,
        required: true,
    })
    public frequency!: ISchedulerConfig;

    @Prop({
        type: Array,
        required: true,
    })
    public recipients!: IRecipient[];

    @Prop({
        type: Array,
        required: true,
    })
    public commonErrors!: Error[];

    @Prop({
        type: Boolean,
        default: false,
    })
    public isEdit!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    public isLoading!: boolean;

    private form: IForm = {
        [SECTIONS.PROPERTIES]: {
            dataType: this.properties.dataType,
            fileType: this.properties.fileType.value,
            dateRange: this.properties.dateRange ? this.properties.dateRange.value : undefined,
        },
        [SECTIONS.CUSTOM_COLUMNS]: this.customColumns.reduce((acc, column) => {
            acc[column.name] = column.value;
            return acc;
        }, {} as { [propName: string]: boolean; }),
        [SECTIONS.FILTERS]: this.filters.reduce((acc, filter) => {
            if (filter.options && Array.isArray(filter.options[0])) {
                acc[filter.name] = {
                    key: (filter.value as [Value, Value])[0],
                    value: (filter.value as [Value, Value])[1],
                };
            } else {
                acc[filter.name] = filter.value as Value;
            }
            return acc;
        }, {} as { [propName: string]: Value | { key: Value, value: Value }; }),
        [SECTIONS.FREQUENCY]: { ...this.frequency },
        [SECTIONS.RECIPIENTS]: this.recipients.reduce((acc, recipient, index) => {
            acc[index] = recipient;
            return acc;
        }, {} as { [propName: number]: IRecipient; }),
    };

    private errorsData: Error | null = null;

    get sections() {
        return SECTIONS;
    }

    get errors() {
        return this.errorsData;
    }

    set errors(value: Error | null) {
        this.errorsData = value;
    }

    get isButtonDisabled() {
        return !!this.errorsData || this.isLoading;
    }

    handleChange(sectionName: SECTIONS, payload: { propName: string, value: string | number | boolean | null }) {
        if (payload.value === null) {
            delete this.form[sectionName][payload.propName];
        } else {
            this.form[sectionName][payload.propName] = payload.value;
        }

        const errors = this.validateForm();
        this.errorsData = errors.length ? errors[0] : null;
    }

    handleSubmit() {
        const errors = this.validateForm();

        if (errors.length) {
            [this.errorsData] = errors;
            return;
        }

        this.$emit('submit', this.form);
    }

    validateForm() {
        const errors: Error[] = [];

        Object.entries(this.form[SECTIONS.PROPERTIES]).forEach(([key, value]) => {
            if (!value && key !== 'dateRange') {
                errors.push(new Error(`Empty ${key} value`));
            }
        });

        Object.entries(this.form[SECTIONS.FILTERS]).forEach(([key, value]) => {
            if (Array.isArray(value) && !value.length) {
                errors.push(new Error(`At least one value in ${key} have to be selected`));
            } else if (value === null || value === undefined) {
                errors.push(new Error(`Empty ${key} value`));
            }
        });

        if (!Object.keys(this.form[SECTIONS.RECIPIENTS]).length) {
            errors.push(new Error('At least one recipient have to be selected'));
        } else {
            Object.entries(this.form[SECTIONS.RECIPIENTS]).forEach(([key, value]) => {
                if (!(value as IRecipient).isValid) {
                    errors.push(new Error(`${(value as IRecipient).value} wrong email address`));
                }
            });
        }

        return errors;
    }
}
