import CommonPopup from './components/common-popup.vue';
import ReportItemMenu from './components/report-item-menu.vue';
import NewReport from './components/new-report';
import ReportsList from './components/reports-list.vue';

export default CommonPopup;

export {
    ReportsList,
    NewReport,
    ReportItemMenu,
};
