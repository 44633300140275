






















import { Prop, Component, Vue } from 'vue-property-decorator';

interface InputEvent extends KeyboardEvent {
    data: string;
}

@Component
export default class Tag extends Vue {
    @Prop({
        type: Number,
        required: true,
    })
    id!: number;

    @Prop({
        type: String,
        default: '',
    })
    value!: string;

    private isValid: boolean = true;
    private isFocused: boolean = false;

    mounted() {
        if (!this.$refs || !this.$refs.email) {
            return;
        }

        const email = this.$refs.email as HTMLInputElement;

        const { length } = email.value;
        if (length) {
            email.style.width = length ? this.calculateTextWidth(email.value) : '10px';
            return;
        }

        email.focus();
        this.isFocused = true;
    }

    handleClose() {
        this.$emit('close', { id: this.id, value: '', isValid: true });
    }

    handleChange(e: InputEvent) {
        const input = e.target as HTMLInputElement;
        const { length } = input.value;
        this.isValid = true;

        if (length > 50) {
            this.$emit('change', { id: this.id, value: this.value, isValid: false });
            input.value = this.value;
            return;
        }

        input.style.width = length ? this.calculateTextWidth(input.value) : '10px';
    }

    calculateTextWidth(value: string) {
        const text = document.createElement('span');
        document.body.appendChild(text);

        text.style.fontFamily = '"Lato-Regular", sans-serif';
        text.style.fontSize = '14px';
        text.style.position = 'absolute';
        text.style.whiteSpace = 'no-wrap';
        text.innerHTML = value;

        const width = `${Math.ceil(text.clientWidth) + 5}px`;

        document.body.removeChild(text);

        return width;
    }

    handleBlur(e: InputEvent) {
        const input = e.currentTarget as HTMLInputElement;
        this.isFocused = false;
        this.isValid = this.validateEmail(input.value);
        this.$emit('change', { id: this.id, value: input.value, isValid: this.isValid });
    }

    handleFocus() {
        this.isFocused = true;
    }

    handleClick(e: MouseEvent) {
        e.stopPropagation();
    }

    validateEmail(email: string) {
        const validEmailPattern = /^[^@\s]+@[^@\s.]+(\.[^@.\s]+[^@.\s]){1,2}$/;
        return validEmailPattern.test(email);
    }
}
