












import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';

@Component({
    components: { CustomSelect },
})
export default class FileTypeFilter extends Vue {
    @Prop({
        type: Object,
        required: true,
    })
    dateRange!: {
        options: number[];
        value: number;
    };

    private selectedRange = this.dateRange.value;

    get range() {
        return this.selectedRange;
    }

    set range(value: number) {
        this.selectedRange = value;
        this.$emit('change', value);
    }

    get items(): Item[] {
        return this.dateRange.options
            .map(value => ({ name: `${value} Days`, value }));
    }
}
