












import { Component, Vue } from 'vue-property-decorator';
import TagsWrapper from './tags-wrapper.vue';

@Component({
    components: { TagsWrapper },
})
export default class RecipientsSection extends Vue {}
