







































import { Prop, Vue, Component } from 'vue-property-decorator';
import { Select, Option, Checkbox } from 'element-ui';
import * as _ from 'lodash';

export interface Item {
    value: number | string;
    name: string;
    disabled?: boolean;
}

@Component({
    components: {
        'el-select': Select,
        'el-option': Option,
        'el-checkbox': Checkbox,
    },
})
export default class CustomSelect extends Vue {
    @Prop({
        required: false,
        type: [Number, String, Array, Boolean],
    })
    private value?: number | string | number[] | string[] | boolean;

    @Prop({
        required: true,
        type: Array as () => Item[],
        validator(value: any): boolean {
            return value
                .every((item: any) => (
                    'value' in item
                    && 'name' in item
                    && (
                        typeof item.value === 'number'
                        || typeof item.value === 'string'
                        || typeof item.value === 'boolean'
                        || item.value === null
                    )
                    && typeof item.name === 'string'
                ));
        },
    })
    private items!: Item[];

    @Prop({
        required: false,
        type: String,
    })
    private label!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    private disabled?: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private multiple?: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    private filterable?: boolean;

    @Prop({
        type: [String, Number],
        required: false,
    })
    private title?: string | number;

    @Prop({
        required: false,
        type: String,
    })
    private placeholder!: string;

    @Prop({
        type: Number,
        default: 0,
    })
    private stickyHeader!: number;

    onChange(value: number | string) {
        this.$emit('input', value);
    }

    extraMarginDown(stickyValue: string): number {
        return Object.keys(this.$slots).length * Number(stickyValue);
    }
}
