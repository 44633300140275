







































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import ReportsList from './reports-list.vue';
import NewReport from './new-report';
import {
    IFilterItem, IProperties, ISchedulerConfig, IRecipient, ICustomColumn, IForm,
} from '../interfaces';
import ScheduledItemModel from '../models/scheduled-item.model';
import ReportsService, { ReportsServiceS } from '../reports.service';

enum PAGES {
    LIST,
    NEW,
    EDIT,
}

@Component({
    components: {
        ReportsList,
        NewReport,
    },
})
export default class CommonPopup extends Vue {
    @Inject(ReportsServiceS) private reportsService!: ReportsService;

    @Prop({
        type: Object,
        required: true,
    })
    public properties!: IProperties;

    @Prop({
        type: Array,
        default: [],
    })
    public customColumns!: ICustomColumn[];

    @Prop({
        type: Array,
        default: [],
    })
    public filters!: IFilterItem[];

    @Prop({
        type: Object,
        required: true,
    })
    public frequency!: ISchedulerConfig;

    @Prop({
        type: Array,
        required: true,
    })
    public recipients!: IRecipient[];

    @Prop({
        type: Boolean,
        default: false,
    })
    public isServiceLoading!: boolean;

    page = PAGES.LIST;
    reportToEdit: ScheduledItemModel | null = null;

    public errors: Error[] = [];

    beforeDestroy() {
        this.reportsService.resetReports();
    }

    get PAGES() {
        return PAGES;
    }

    get reportToEditFilters(): IFilterItem[] {
        if (!this.reportToEdit || !this.reportToEdit.filters) {
            return this.filters;
        }

        const editFilters = this.filters.map(f => {
            let value = f.name === 'compset'
                ? this.reportToEdit!.compsetId
                : this.reportToEdit!.filters![f.name];

            if (!Array.isArray(value) && typeof value === 'object') {
                value = [value.key, value.value];
            }

            return {
                ...f,
                value: value || (f.options[0] as Item).value,
            };
        }) as IFilterItem[];

        return editFilters;
    }

    get reportToEditCustomColumns(): ICustomColumn[] {
        if (!this.reportToEdit || !this.reportToEdit.filters || !this.reportToEdit.filters.columnsOptions) {
            return this.customColumns;
        }

        const editColumns = this.customColumns.map(cc => {
            const value = this.reportToEdit!.columnsOptions![cc.name];
            return {
                ...cc,
                value,
            };
        });

        return editColumns;
    }

    get reportToEditProperties(): IProperties {
        if (!this.reportToEdit || !this.reportToEdit.numberOfDays) {
            return this.properties;
        }

        const editProperties = {
            ...this.properties,
        };

        if (this.properties.dateRange) {
            editProperties.dateRange = {
                options: [...this.properties.dateRange.options],
                value: this.reportToEdit.numberOfDays,
            };
        }

        return editProperties;
    }

    get reportToEditFrequency(): ISchedulerConfig {
        if (!this.reportToEdit || !this.reportToEdit.schedulerConfig) {
            return this.frequency;
        }

        const editFrequency = Object.fromEntries(
            Object.entries(this.frequency).map(([key, value]) => {
                const editReportValue = this.reportToEdit!.schedulerConfig[key as keyof ISchedulerConfig];
                if (editReportValue !== null && editReportValue !== undefined) {
                    return [key, editReportValue];
                }
                return [key, value];
            }),
        ) as ISchedulerConfig;

        return editFrequency;
    }

    get reportToEditRecipients(): IRecipient[] {
        if (!this.reportToEdit || !this.reportToEdit.recipients) {
            return this.recipients;
        }

        const editRecipients = this.reportToEdit.recipients.map(r => ({
            value: r,
            isValid: true,
        }));

        return editRecipients;
    }

    get isLoading() {
        return this.isServiceLoading || this.reportsService.loading.isLoading() || !this.reportsService.loading.isInitialized;
    }

    toEdit(report: ScheduledItemModel) {
        this.reportToEdit = report;
        this.switchPage(PAGES.EDIT);
    }

    switchPage(page: PAGES) {
        this.page = page;
    }

    async handleNewReport(form: IForm) {
        try {
            await this.reportsService.addReport(form);
        } catch (e) {
            this.errors.push(e as Error);
            return;
        }

        this.switchPage(PAGES.LIST);

        this.errors = [];
    }

    async handleEditReport(form: IForm) {
        if (!this.reportToEdit) {
            return;
        }

        try {
            await this.reportsService.updateReport(this.reportToEdit.id, form);
        } catch (e) {
            this.errors.push(e as Error);
            return;
        }

        this.switchPage(PAGES.LIST);

        this.errors = [];
        this.reportToEdit = null;
    }

    async handleDeleteReport(id: string) {
        await this.reportsService.deleteReport(id);
    }
}
