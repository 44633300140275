






















import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';

@Component({
    components: {
        CustomSelect,
    },
})
export default class ComplexSelect extends Vue {
    @Prop({
        type: Array,
        required: true,
    })
    value!: [(string | number), (string | number)];

    @Prop({
        type: Array,
        required: true,
    })
    items!: [Item[], Item[][]];

    @Prop({
        type: String,
        default: '',
    })
    label!: string;

    @Prop({
        type: Array,
        default: () => [false, false],
    })
    disabled!: [boolean, boolean];

    public index = this.items[0].findIndex(item => item.value === this.value[0]);

    handleMainChange(e: string) {
        this.index = this.items[0].findIndex(item => item.value === e);
        this.value[1] = this.items[1][this.index][0].value;
        this.$emit('input', [e, this.value[1]]);
    }

    handleChange(e: string) {
        this.$emit('input', [this.value[0], e]);
    }
}
