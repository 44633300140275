











import moment from 'moment';
import CustomSelectBordered, { Item } from '@/modules/common/components/ui-kit/custom-select-bordered.vue';
import {
    Component, Prop, Vue,
} from 'vue-property-decorator';
import loop24 from '@/modules/common/filters/loop-24.filter';

@Component({
    components: { CustomSelectBordered },
})
export default class TimeFilter extends Vue {
    @Prop({
        required: false,
        type: Boolean,
        default: false,
    })
    disabled!: boolean;

    @Prop({
        type: Number,
        required: true,
    })
    value!: number;

    get time() {
        return this.value;
    }

    set time(v: number) {
        this.$emit('input', v);
    }

    get timeItems(): Item[] {
        return [...Array(24).keys()].map((value): Item => ({
            value: loop24(value + (new Date().getTimezoneOffset() / 60)),
            name: moment({ hour: value, minute: 0 }).format('hh:mm A'),
        }));
    }
}
