







import { Component, Vue, Prop } from 'vue-property-decorator';
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';

@Component({
    components: { CustomSelect },
})
export default class FileTypeFilter extends Vue {
    @Prop({
        type: Object,
        required: true,
    })
    fileType!: {
        options: string[];
        value: string;
    };

    private fileTypeValue: string = this.fileType.value;

    get value() {
        return this.fileTypeValue;
    }

    set value(value: string) {
        this.fileTypeValue = value;
        this.$emit('change', value);
    }

    get fileTypeItems(): Item[] {
        return this.fileType.options.map(f => ({
            name: f,
            value: f,
        }));
    }
}
