



















import { Component, Vue, Prop } from 'vue-property-decorator';

export enum BOX_COLORS {
    BLUE = 'blue',
    ORANGE = 'orange',
    PURPLE = 'purple',
    GREEN = 'green'
}

@Component
export default class CustomCheckbox extends Vue {
    @Prop({ required: true, type: Boolean })
    value!: boolean;

    @Prop({ required: false, type: String })
    label!: string;

    @Prop({ type: Boolean })
    disabled!: boolean;

    @Prop({
        required: false,
        type: String,
        validator(value: any): boolean {
            return value === 'blue' || value === 'orange' || value === 'purple' || value === 'green';
        },
    })
    color!: BOX_COLORS;

    handleChange(checked: boolean) {
        this.$emit('input', checked);
    }

    get colorClass() {
        return {
            blue: this.color === 'blue',
            orange: this.color === 'orange',
            purple: this.color === 'purple',
            green: this.color === 'green',
        };
    }
}
